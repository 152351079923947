
























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { GridRow } from '@/types/cms/components/';
import { ProductInformation } from '@/types/neuhaus-website/cms/pages/products';
import { CloneHelper } from '@/utils/clonehelper';

/**
 * CMSAdminComponentProductInformationFormDialog component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component
export default class CMSAdminComponentProductInformationFormDialog extends Vue {
    // Prop of type boolean that defined whether the dialog should be shown or not
    @Prop(Boolean) readonly showDialog: boolean | undefined;

    // Prop that holds the pageProductInformation data
    @Prop({type: Object as () => GridRow<ProductInformation> }) readonly editedProductInformationItem: GridRow<ProductInformation> | undefined;

    // Prop that holds the id of the edited pageProductInformation
    @Prop(Number) readonly editedProductInformationIndex: number | undefined;

    // An crud string that defines the mode of the dialog
    @Prop(String) readonly dialogMode: string | undefined;

    // Boolean that defines wheter the form data is valid or not
    private formValid = false;

    /**
     * @returns the title for the form
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    get formTitle(): string {
        return (this.dialogMode) ? this.$t('form.titles.' + this.dialogMode + 'ProductInformation').toString() : '';
    }

    /**
     * Resets the form validation
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private resetFormValidation() {
        if (this.$refs.form) {
            (this.$refs.form as Vue & { resetValidation: () => void }).resetValidation();
        }
    }

    /**
     * Saves/Updates or deletes the pageProductInformation
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private saveProductInformation() {
        this.resetFormValidation();
        this.$emit('productInformationSaved', 
            this.dialogMode, 
            this.editedProductInformationIndex,
            CloneHelper.deepCloneObject(this.editedProductInformationItem)
        );
    }

    /**
     * Closes the dialog
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private closeDialog() {
        this.resetFormValidation();
        this.$emit('dialogClosed');
    }
}
